import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import '../Css/AboutUs.css';

const teamMembers = [
  {
    name: 'John Doe',
    role: 'CEO & Founder',
    image: 'https://usdgme.org/app/uploads/2020/09/PersonJohn.jpg',
    bio: 'John is a visionary leader with over 15 years of experience in the industry.',
  },
  {
    name: 'Jane Smith',
    role: 'Chief Marketing Officer',
    image: 'https://th.bing.com/th/id/OIP.07cqwUUZFSe354bYUPNaGQHaJ4?rs=1&pid=ImgDetMain',
    bio: 'Jane is an expert in digital marketing and has helped scale our business significantly.',
  },
  {
    name: 'Emily Johnson',
    role: 'Lead Developer',
    image: 'https://th.bing.com/th/id/OIP.LQwGSeEo0yaimv-QcXvBcAHaKl?w=1433&h=2048&rs=1&pid=ImgDetMain',
    bio: 'Emily is a talented developer with a passion for creating innovative solutions.',
  },
];

const AboutUs = () => {
  return (
    <div className="about-us">
      {/* Header Section */}
      <section className="hero-section bg-primary text-light text-center py-5">
        <Container>
          <h1 className="display-4">About Us</h1>
        </Container>
      </section>

      {/* Company Overview Section */}
      <section className="company-overview py-5">
        <Container>
          <Row className="align-items-center">
            <Col md={6}>
              <h2>Who We Are</h2>
              <p>
                We are a passionate team dedicated to providing the best services to our customers.
                Our company has been at the forefront of innovation, constantly evolving to meet the needs
                of our clients. We believe in quality, integrity, and customer satisfaction.
              </p>
            </Col>
            <Col md={6}>
              <img
                src="https://th.bing.com/th/id/R.6a2ef8854f3fc6ab81d91867e26679a8?rik=6SaeuAzSFYBrvA&pid=ImgRaw&r=0"
                alt="Company"
                className="img-fluid rounded shadow"
              />
            </Col>
          </Row>
        </Container>
      </section>

      {/* Mission and Vision Section */}
      <section className="mission-vision bg-light py-5">
        <Container>
          <Row>
            <Col md={6} className="text-center mb-4">
              <h2>Our Mission</h2>
              <p>To empower businesses and individuals by providing innovative and reliable solutions.</p>
            </Col>
            <Col md={6} className="text-center mb-4">
              <h2>Our Vision</h2>
              <p>To be a global leader in the industry, recognized for our customer-centric approach and excellence.</p>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Team Section */}
      <section className="team-section py-5">
        <Container>
          <h2 className="text-center mb-5">Meet Our Team</h2>
          <Row>
            {teamMembers.map((member, index) => (
              <Col md={4} key={index} className="mb-4">
                <Card className="text-center shadow">
                  <Card.Img variant="top" src={member.image} alt={member.name} />
                  <Card.Body>
                    <Card.Title>{member.name}</Card.Title>
                    <Card.Text>{member.role}</Card.Text>
                    <p>{member.bio}</p>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </section>

      {/* Call to Action Section */}
      <section className="cta-section bg-primary text-light text-center py-5">
        <Container>
          <h2>Want to Join Our Team?</h2>
          <p>We are always looking for talented and passionate individuals. Reach out to us!</p>
          <Button variant="light" href="/contact">Get in Touch</Button>
        </Container>
      </section>
    </div>
  );
};

export default AboutUs;
