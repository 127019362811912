import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Css/ViewProfile.css';
import { auth, db } from './Database/firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc, updateDoc } from 'firebase/firestore';

const ViewProfile = () => {
    const [user, setUser] = useState(null);
    const [userDetails, setUserDetails] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [formValues, setFormValues] = useState({ name: '', phone: '', address: '' });

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
            if (currentUser) {
                setUser(currentUser);
                try {
                    const userDocRef = doc(db, 'users', currentUser.uid);
                    const userDocSnap = await getDoc(userDocRef);
                    if (userDocSnap.exists()) {
                        setUserDetails(userDocSnap.data());
                        setFormValues(userDocSnap.data());
                    } else {
                        console.log('No such document!');
                    }
                } catch (error) {
                    console.error('Error fetching user details:', error);
                }
            } else {
                // Handle unauthenticated state
                // navigate('/login'); // Uncomment if using react-router
            }
        });

        return () => unsubscribe();
    }, []);

    const handleModalClose = () => setShowModal(false);
    const handleModalShow = () => setShowModal(true);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const userDocRef = doc(db, 'users', user.uid);
            await updateDoc(userDocRef, formValues);
            setUserDetails(formValues);
            setShowModal(false);
        } catch (error) {
            console.error('Error updating user details:', error);
        }
    };

    if (!user || !userDetails) {
        return <div className="container mt-5 text-center">Loading...</div>;
    }

    return (
        <div className="container mt-5">
            <div className="row justify-content-center">
                <div className="col-md-10 col-lg-8">
                    <div className="profile-card">
                        <div className="profile-header text-center">
                            <img
                                src={user.photoURL || 'https://th.bing.com/th/id/R.fa0ca630a6a3de8e33e03a009e406acd?rik=UOMXfynJ2FEiVw&riu=http%3a%2f%2fwww.clker.com%2fcliparts%2ff%2fa%2f0%2fc%2f1434020125875430376profile.png&ehk=73x7A%2fh2HgYZLT1q7b6vWMXl86IjYeDhub59EZ8hF14%3d&risl=&pid=ImgRaw&r=0'}
                                alt="Profile"
                                className="profile-picture mb-3"
                            />
                            <h2 className="profile-name mb-2">{userDetails.name || 'John Doe'}</h2>
                            <p className="profile-email mb-2"><strong>Email:</strong> {user.email}</p>
                            <p className="profile-phone mb-2"><strong>Phone:</strong> {userDetails.phone || 'Not Provided'}</p>
                            <p className="profile-address mb-4"><strong>Address:</strong> {userDetails.address || 'Not Provided'}</p>
                            <button className="btn btn-primary" onClick={handleModalShow}>
                                Edit Profile
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Edit Profile Modal */}
            <div
                className={`modal fade ${showModal ? 'show d-block' : ''}`}
                tabIndex="-1"
                role="dialog"
                style={{ display: showModal ? 'block' : 'none' }}
            >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content rounded-lg shadow-lg">
                        <div className="modal-header border-bottom-0">
                            <h5 className="modal-title">Edit Profile</h5>
                            <button
                                type="button"
                                className="close"
                                onClick={handleModalClose}
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label htmlFor="name">Name</label>
                                    <input
                                        type="text"
                                        id="name"
                                        name="name"
                                        value={formValues.name}
                                        onChange={handleChange}
                                        className="form-control form-control-lg"
                                        placeholder="Enter your name"
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="phone">Phone</label>
                                    <input
                                        type="text"
                                        id="phone"
                                        name="phone"
                                        value={formValues.phone}
                                        onChange={handleChange}
                                        className="form-control form-control-lg"
                                        placeholder="Enter your phone number"
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="address">Address</label>
                                    <input
                                        type="text"
                                        id="address"
                                        name="address"
                                        value={formValues.address}
                                        onChange={handleChange}
                                        className="form-control form-control-lg"
                                        placeholder="Enter your address"
                                        required
                                    />
                                </div>
                                <div className="modal-footer border-top-0">
                                    <button type="submit" className="btn btn-primary btn-lg">Save Changes</button>
                                    <button type="button" className="btn btn-secondary btn-lg" onClick={handleModalClose}>Cancel</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ViewProfile;
