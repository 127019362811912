import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, useNavigate } from 'react-router-dom';
import { auth } from './Database/firebase';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import logo from '../assets/banners/logo/logo.jpg';

const Navbar = () => {
    const [user, setUser] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            setUser(currentUser);
        });

        return () => unsubscribe();
    }, []);

    const handleLogout = async () => {
        try {
            await signOut(auth);
            setUser(null);
            navigate('/login');
        } catch (error) {
            console.error('Error logging out:', error);
        }
    };

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-primary text-light">
            <div className="container">
                <Link className="navbar-brand text-light display-6" to="/oneMarket">
                    <img
                        src={logo}
                        alt="One Market Logo"
                        style={{ width: '50px', height: '50px', marginRight: '10px', marginLeft: '-5px' }}
                    />
                    One Market
                </Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                    aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav ms-auto" style={{ marginRight: '-40px' }}>
                        {user ? (
                            <>
                                <li className="nav-item">
                                    <span className="nav-link text-light">
                                        <i className="fas fa-user-circle"></i> Hello, {user.displayName || user.email}
                                    </span>
                                </li>
                                <li className="nav-item dropdown">
                                    <button className="nav-link dropdown-toggle text-light btn btn-link" id="navbarDropdown" type="button"
                                        data-bs-toggle="dropdown" aria-expanded="false">
                                        <i className="fas fa-box"></i> Options
                                    </button>
                                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown"
                                        style={{
                                            backgroundColor: '#007bff',
                                            color: '#fff',
                                            padding: '0.5rem 0',
                                        }}
                                    >
                                        <li>
                                            <Link className="dropdown-item d-flex align-items-center" to="/oneMarket">
                                                <i className="fas fa-box me-2"></i> Products
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="dropdown-item d-flex align-items-center" to="/manageProducts">
                                                <i className="fas fa-cogs me-2"></i> Manage Products
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="dropdown-item d-flex align-items-center" to="/addProduct">
                                                <i className="fas fa-plus-circle me-2"></i> Advertise your Product
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="dropdown-item d-flex align-items-center" to="/viewProfile">
                                                <i className="fas fa-user me-2"></i> View Profile
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link text-light" to="/about">
                                        <i className="fas fa-info-circle me-2"></i> About Us
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link text-light" to="/contact">
                                        <i className="fas fa-envelope me-2"></i> Contact Us
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <button className="btn btn-link nav-link text-light" onClick={handleLogout}>
                                        <i className="fas fa-sign-out-alt"></i> Logout
                                    </button>
                                </li>
                            </>
                        ) : (
                            <>
                                <li className="nav-item">
                                    <Link className="nav-link text-light" to="/login">
                                        <i className="fas fa-sign-in-alt"></i> Login
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link text-light" to="/about">
                                        <i className="fas fa-info-circle me-2"></i> About Us
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link text-light" to="/contact">
                                        <i className="fas fa-envelope me-2"></i> Contact Us
                                    </Link>
                                </li>
                            </>
                        )}
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
