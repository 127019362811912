import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { db } from './Database/firebase';
import { doc, getDoc } from 'firebase/firestore';
import '../Css/ProductDetail.css';
import { FaArrowLeft, FaArrowRight, FaEnvelope, FaUser, FaWhatsapp } from 'react-icons/fa';

function ProductDetail() {
    const { productId } = useParams();
    const [product, setProduct] = useState(null);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    useEffect(() => {
        const fetchProduct = async () => {
            try {
                const docRef = doc(db, 'products', productId);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    setProduct(docSnap.data());
                } else {
                    console.error('No such document!');
                }
            } catch (error) {
                console.error('Error fetching product:', error);
            }
        };

        fetchProduct();
    }, [productId]);

    const showNextImage = () => {
        if (product && product.images.length > 0) {
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % product.images.length);
        }
    };

    const showPrevImage = () => {
        if (product && product.images.length > 0) {
            setCurrentImageIndex((prevIndex) => (prevIndex - 1 + product.images.length) % product.images.length);
        }
    };

    const handleWhatsAppClick = () => {
        if (product && product.contactDetails) {
            // Replace with your WhatsApp link or number
            const phoneNumber = product.contactDetails.replace(/\D/g, '');
            window.open(`https://wa.me/${phoneNumber}`, '_blank');
        }
    };

    return (
        <div className="container mt-5 product-detail-container">
            {product ? (
                <div className="product-detail-content">
                    <div className="image-carousel">
                        <button className="arrow left" onClick={showPrevImage}>
                            <FaArrowLeft size={20} />
                        </button>
                        <img
                            src={product.images[currentImageIndex]}
                            alt={product.productName}
                            className="carousel-image"
                        />
                        <button className="arrow right" onClick={showNextImage}>
                            <FaArrowRight size={20} />
                        </button>
                    </div>
                    <div className="product-info">
                        <h1 className="product-title">
                            {product.productName}
                        </h1>
                        <p className="product-category">
                            <strong>Category:</strong> {product.category}
                        </p>
                        <p className="product-description">
                            <strong>Description:</strong> {product.description}
                        </p>
                        <p className="product-price">
                            LKR {product.price}
                        </p>
                        <p className="product-seller">
                            <FaUser size={18} /> <strong>Seller:</strong> {product.sellerName}
                        </p>
                        <p className="product-contact">
                            <FaEnvelope size={18} /> <strong>Contact Details:</strong> 
                            <span>{product.contactDetails}</span>
                            <button onClick={handleWhatsAppClick} className="whatsapp-btn">
                                <FaWhatsapp size={18} /> Chat on WhatsApp
                            </button>
                        </p>
                    </div>
                </div>
            ) : (
                <p>Product not found</p>
            )}
        </div>
    );
}

export default ProductDetail;
