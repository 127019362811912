import React, { useState, useEffect } from 'react';
import { db } from '../Database/firebase';
import { collection, getDocs, doc, deleteDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import './ProductManage.css';

const ProductManage = () => {
    const [products, setProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [filters, setFilters] = useState({
        name: '',
        category: ''
    });
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate(); // Initialize useNavigate

    useEffect(() => {
        const fetchProducts = async () => {
            setIsLoading(true);
            try {
                const productSnapshot = await getDocs(collection(db, 'products'));
                const productList = productSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setProducts(productList);

                // Extract categories
                const uniqueCategories = [...new Set(productList.map(product => product.category).filter(Boolean))];
                setCategories(uniqueCategories);
            } catch (error) {
                console.error('Error fetching products:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchProducts();
    }, []);

    useEffect(() => {
        const results = products.filter(product =>
            (product.productName?.toLowerCase().includes(filters.name.toLowerCase()) || !filters.name) &&
            (product.category === filters.category || !filters.category)
        );
        setFilteredProducts(results);
    }, [filters, products]);

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters(prevFilters => ({ ...prevFilters, [name]: value }));
    };

    const handleDelete = async (id) => {
        try {
            await deleteDoc(doc(db, 'products', id));
            setProducts(products.filter(product => product.id !== id));
        } catch (error) {
            console.error('Error deleting product:', error);
        }
    };

    if (isLoading) {
        return <div className="loading">Loading...</div>;
    }

    return (
        <div className="product-manage-wrapper">
            <button className="btn btn-primary mb-4" onClick={() => navigate('/admin-dashboard')}>
                Back to Dashboard
            </button>
            <h3 className="text-center mb-4">Product Management</h3>
            
            {/* Filter Options */}
            <div className="mb-4">
                <input
                    type="text"
                    name="name"
                    className="form-control mb-2"
                    placeholder="Filter by name"
                    value={filters.name}
                    onChange={handleFilterChange}
                />
                <select
                    name="category"
                    className="form-control mb-2"
                    value={filters.category}
                    onChange={handleFilterChange}
                >
                    <option value="">Select Category</option>
                    {categories.map(category => (
                        <option key={category} value={category}>{category}</option>
                    ))}
                </select>
            </div>
            
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Category</th>
                        <th>Description</th>
                        <th>Price</th>
                        <th>Address</th>
                        <th>Contact</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredProducts.map(product => (
                        <tr key={product.id}>
                            <td>{product.id}</td>
                            <td>{product.productName || 'N/A'}</td>
                            <td>{product.category || 'N/A'}</td>
                            <td>{product.description || 'N/A'}</td>
                            <td>LKR{product.price || 'N/A'}</td>
                            <td>{product.address || 'N/A'}</td>
                            <td>{product.contactDetails || 'N/A'}</td>
                            <td>
                                <button className="btn btn-danger btn-sm" onClick={() => handleDelete(product.id)}>Delete</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default ProductManage;
