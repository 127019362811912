// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import ProductList from './components/ProductList';
import ProductDetail from './components/ProductDetail';
import AddProduct from './components/AddProduct';
import Navbar from './components/Navbar';
import Login from './components/Login';
import Signup from './components/Signup';
import AboutUs from './components/AboutUs';
import ContactUs from './components/ContactUs';
import ManageProducts from './components/ManageProducts';
import ViewProfile from './components/ViewProfile';
import AdminDashboard from './components/Admin/AdminDashboard';
import UserManage from './components/Admin/UserManage';
import ProductManage from './components/Admin/ProductManage';
import ProtectedRoute from './components/ProtectedRoute';

const App = () => {
  return (
    <Router>
      <Navbar />
      <div className="container mt-4">
        <Routes>
          <Route path="/oneMarket" element={<ProductList />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route
            path="/manageProducts"
            element={<ProtectedRoute element={ManageProducts} />}
          />

          <Route
            path="/addProduct"
            element={<ProtectedRoute element={AddProduct} />}
          />

          <Route
            path="/viewProfile"
            element={<ProtectedRoute element={ViewProfile} />}
          />

          <Route
            path="//products/:productId"
            element={<ProtectedRoute element={ProductDetail} />}
          />

          {/* Protect the admin dashboard route */}
          <Route
            path="/admin-dashboard"
            element={<ProtectedRoute element={AdminDashboard} roleRequired="Admin" />}
          />

          <Route
            path="/admin-dashboard/user-manage"
            element={<ProtectedRoute element={UserManage} roleRequired="Admin" />}
          />
          <Route
            path="/admin-dashboard/product-manage"
            element={<ProtectedRoute element={ProductManage} roleRequired="Admin" />}
          />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
