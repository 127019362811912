import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBQzOZwLdQUBsiIDmT5OlvrV7j-MaqG-eY",
  authDomain: "onemarket-9f957.firebaseapp.com",
  projectId: "onemarket-9f957",
  storageBucket: "onemarket-9f957.appspot.com",
  messagingSenderId: "463464031378",
  appId: "1:463464031378:web:3f6de53bf8be204f27b24b",
  measurementId: "G-5BS64Z19MB"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const realTimeDb = getDatabase(app);
export const storage = getStorage(app);
