import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { db } from '../Database/firebase';
import { collection, getDocs } from 'firebase/firestore';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement } from 'chart.js';
import { FaUsers, FaBox, FaChartPie, FaBars, FaTimes } from 'react-icons/fa';
import './AdminDashboard.css';

// Register the required components for Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement // Required for Pie charts
);

const AdminDashboard = () => {
  const [productCount, setProductCount] = useState(0);
  const [mostPostedProduct, setMostPostedProduct] = useState(null);
  const [totalUsers, setTotalUsers] = useState(0);
  const [productDistribution, setProductDistribution] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  useEffect(() => {
    // Fetching data
    const fetchStats = async () => {
      setIsLoading(true);
      try {
        // Get product count and product data
        const productSnapshot = await getDocs(collection(db, 'products'));
        const productDocs = productSnapshot.docs.map(doc => doc.data());
        setProductCount(productSnapshot.size);

        // Calculate most posted product
        const productCounts = productDocs.reduce((acc, product) => {
          acc[product.productName] = (acc[product.productName] || 0) + 1;
          return acc;
        }, {});
        const mostPostedProduct = Object.keys(productCounts).reduce((a, b) => productCounts[a] > productCounts[b] ? a : b, null);
        setMostPostedProduct(mostPostedProduct);

        // Calculate product distribution
        const categoryCounts = productDocs.reduce((acc, product) => {
          acc[product.category] = (acc[product.category] || 0) + 1;
          return acc;
        }, {});
        setProductDistribution(Object.entries(categoryCounts).map(([category, count]) => ({ category, count })));

        // Get total users count
        const userSnapshot = await getDocs(collection(db, 'users'));
        setTotalUsers(userSnapshot.size);

      } catch (error) {
        console.error('Error fetching statistics:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchStats();
  }, []);

  // Memoize the product distribution chart data to avoid unnecessary recalculations
  const productDistributionChartData = useMemo(() => ({
    labels: productDistribution.map(item => item.category),
    datasets: [
      {
        data: productDistribution.map(item => item.count),
        backgroundColor: [
          '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40',
          '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40'
        ],
        borderColor: '#fff',
        borderWidth: 2,
        hoverBackgroundColor: [
          '#FF6384DD', '#36A2EBDD', '#FFCE56DD', '#4BC0C0DD', '#9966FFDD', '#FF9F40DD',
          '#FF6384DD', '#36A2EBDD', '#FFCE56DD', '#4BC0C0DD', '#9966FFDD', '#FF9F40DD'
        ],
        hoverBorderColor: '#ffffff',
      }
    ]
  }), [productDistribution]);

  return (
    <div className="admin-dashboard-wrapper">
      <nav className={`sidebar ${isCollapsed ? 'collapsed' : ''}`}>
        <button className="toggle-btn" onClick={toggleSidebar}>
          {isCollapsed ? <FaBars /> : <FaTimes />}
        </button>
        <h2 className="sidebar-title">Admin Dashboard</h2>
        <ul className="sidebar-menu">
          <li><Link to="/admin-dashboard/user-manage"><span className="sidebar-icon"><FaUsers /></span>Users</Link></li>
          <li><Link to="/admin-dashboard/product-manage"><span className="sidebar-icon"><FaBox /></span>Products</Link></li>
        </ul>
      </nav>
      <div className="content">
        <h3 className="text-center mb-4">Dashboard Overview</h3>
        {isLoading ? (
          <div className="loading">Loading...</div>
        ) : (
          <>
            <div className="stats-cards">
              <div className="card animated-card">
                <FaBox className="card-icon" />
                <h5>Total Products</h5>
                <p>{productCount}</p>
              </div>
              <div className="card animated-card">
                <FaChartPie className="card-icon" />
                <h5>Most Posted Product</h5>
                <p>{mostPostedProduct || 'N/A'}</p>
              </div>
              <div className="card animated-card">
                <FaUsers className="card-icon" />
                <h5>Total Users</h5>
                <p>{totalUsers}</p>
              </div>
            </div>
            <div className="charts">
              <div className="chart-container">
                <h4 className="chart-heading">Product Distribution</h4>
                <div className="chart animated-chart">
                  <Pie
                    data={productDistributionChartData}
                    options={{
                      responsive: true,
                      plugins: {
                        legend: {
                          position: 'bottom',
                        },
                        tooltip: {
                          callbacks: {
                            label: function (tooltipItem) {
                              return `${tooltipItem.label}: ${tooltipItem.raw}`;
                            },
                          },
                        },
                      },
                    }}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AdminDashboard;
