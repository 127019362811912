import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Carousel from 'react-bootstrap/Carousel';
import '../Css/ProductList.css';
import Footer from './Footer';

// Import banner images
import banner1 from '../assets/banners/2_4.jpg';
import banner2 from '../assets/banners/2_5.jpg';
import banner3 from '../assets/banners/2_6.jpg';

import { db } from './Database/firebase'; 
import { collection, getDocs } from 'firebase/firestore';

const ProductList = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedSubcategory, setSelectedSubcategory] = useState(null);
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [hoveredProductIndex, setHoveredProductIndex] = useState(null);
    const [currentImageIndex, setCurrentImageIndex] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [productsPerPage] = useState(12);

    const banners = [
        { id: 1, src: banner1, alt: 'Banner 1' },
        { id: 2, src: banner2, alt: 'Banner 2' },
        { id: 3, src: banner3, alt: 'Banner 3' },
    ];

    const subcategories = {
        Tools: ['Hand Tools', 'Power Tools', 'Garden Tools'],
        Clothing: ['Men\'s Clothing', 'Women\'s Clothing', 'Kids\' Clothing'],
        Sports: ['Fitness Equipment', 'Outdoor Gear', 'Sports Apparel'],
        Furniture: ['Living Room', 'Bedroom', 'Office Furniture'],
        Electronics: ['Phones', 'Laptops', 'Home Appliances'],
        Books: ['Fiction', 'Non-Fiction', 'Educational'],
        Toys: ['Action Figures', 'Dolls', 'Board Games'],
        Automotive: ['Car Accessories', 'Motorcycle Gear', 'Tools'],
        Home: ['DIY Tools', 'Paints', 'Lighting'],
        Kitchen: ['Cookware', 'Tableware', 'Appliances'],
        HealthBeauty: ['Skincare', 'Haircare', 'Personal Care'],
        Garden: ['Plants', 'Garden Tools', 'Outdoor Furniture'],
        OfficeSupplies: ['Stationery', 'Office Furniture', 'Technology'],
        Jewelry: ['Necklaces', 'Bracelets', 'Rings'],
        Art: ['Paintings', 'Sculptures', 'Prints'],
        Music: ['Instruments', 'Sheet Music', 'Accessories'],
        PetSupplies: ['Food', 'Toys', 'Grooming'],
        Other: ['Miscellaneous'],
    };

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, 'products'));
                const productsList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setProducts(productsList);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching products:', error);
                toast.error('Error fetching products');
                setLoading(false);
            }
        };

        fetchProducts();
    }, []);

    const categoryCounts = products.reduce((acc, product) => {
        acc[product.category] = (acc[product.category] || 0) + 1;
        return acc;
    }, {});

    const categories = Object.keys(categoryCounts);
    const availableSubcategories = selectedCategory ? subcategories[selectedCategory] : [];

    const handleMouseEnter = (index) => {
        setHoveredProductIndex(index);
        if (!currentImageIndex[index]) {
            setCurrentImageIndex((prevState) => ({ ...prevState, [index]: 0 }));
        }
    };

    const handleMouseLeave = () => {
        setHoveredProductIndex(null);
    };

    const showNextImage = (index) => {
        setCurrentImageIndex((prevState) => ({
            ...prevState,
            [index]: (prevState[index] + 1) % products[index].images.length,
        }));
    };

    const showPrevImage = (index) => {
        setCurrentImageIndex((prevState) => ({
            ...prevState,
            [index]: (prevState[index] - 1 + products[index].images.length) % products[index].images.length,
        }));
    };

    const filteredProducts = products.filter(
        (product) =>
            product.productName.toLowerCase().includes(searchTerm.toLowerCase()) &&
            (selectedCategory ? product.category === selectedCategory : true) &&
            (selectedSubcategory ? product.subcategory === selectedSubcategory : true)
    );

    const indexOfLastProduct = currentPage * productsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
    const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);

    const totalPages = Math.ceil(filteredProducts.length / productsPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    
    const handleCategoryChange = (category) => {
        setSelectedCategory(category);
        setSelectedSubcategory(null);
        setCurrentPage(1); // Reset to the first page when a category is selected
    };

    const handleSubcategoryChange = (subcategory) => {
        setSelectedSubcategory(subcategory);
        setCurrentPage(1); // Reset to the first page when a subcategory is selected
    };

    const handleSearchTermChange = (e) => {
        setSearchTerm(e.target.value);
        setCurrentPage(1);
    };

    return (
        <div className="container mt-5">
            {/* Banner Carousel */}
            <Carousel className="banner-carousel mb-4">
                {banners.map((banner) => (
                    <Carousel.Item key={banner.id}>
                        <img
                            className="d-block w-100 banner-img"
                            src={banner.src}
                            alt={banner.alt}
                        />
                        <Carousel.Caption>
                            <h3 className="banner-title">Discover the Best Products</h3>
                            <p className="banner-subtitle">Top-quality items at affordable prices</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                ))}
            </Carousel>

            {/* Category and Search Section */}
            <div className="d-flex flex-column mb-4 category-search-section">
                <div role="group" aria-label="Categories" className="category-group mb-3">
                    <button
                        type="button"
                        className={`category-btn ${selectedCategory === null ? 'active' : ''}`}
                        onClick={() => handleCategoryChange(null)}
                    >
                        All
                    </button>
                    {categories.map((category) => (
                        <button
                            key={category}
                            type="button"
                            className={`category-btn ${selectedCategory === category ? 'active' : ''}`}
                            onClick={() => handleCategoryChange(category)}
                        >
                            {category}
                        </button>
                    ))}
                </div>
                {selectedCategory && (
                    <div role="group" aria-label="Subcategories" className="subcategory-group mb-3">
                        <button
                            type="button"
                            className={`subcategory-btn ${selectedSubcategory === null ? 'active' : ''}`}
                            onClick={() => handleSubcategoryChange(null)}
                        >
                            All Subcategories
                        </button>
                        {availableSubcategories.map((sub) => (
                            <button
                                key={sub}
                                type="button"
                                className={`subcategory-btn ${selectedSubcategory === sub ? 'active' : ''}`}
                                onClick={() => handleSubcategoryChange(sub)}
                            >
                                {sub}
                            </button>
                        ))}
                    </div>
                )}
                <input
                    type="text"
                    placeholder="Search products..."
                    value={searchTerm}
                    onChange={handleSearchTermChange}
                    className="form-control search-bar"
                />
            </div>

            {/* Products Section */}
            <div className="row">
                {loading ? (
                    <div className="text-center col-12">Loading...</div>
                ) : currentProducts.length === 0 ? (
                    <div className="text-center col-12">No products found</div>
                ) : (
                    currentProducts.map((product, index) => (
                        <div
                            key={product.id}
                            className="col-md-4 col-lg-3 mb-4 product-card-container"
                            onMouseEnter={() => handleMouseEnter(index)}
                            onMouseLeave={handleMouseLeave}
                        >
                            <div className="card product-card">
                                {product.images && product.images.length > 0 && (
                                    <>
                                        <img
                                            src={product.images[currentImageIndex[index] || 0]}
                                            className="card-img-top product-image"
                                            alt={product.productName}
                                        />
                                        {hoveredProductIndex === index && product.images.length > 1 && (
                                            <>
                                                <button
                                                    className="carousel-control-prev"
                                                    onClick={() => showPrevImage(index)}
                                                >
                                                    &lt;
                                                </button>
                                                <button
                                                    className="carousel-control-next"
                                                    onClick={() => showNextImage(index)}
                                                >
                                                    &gt;
                                                </button>
                                            </>
                                        )}
                                    </>
                                )}
                                <div className="card-body">
                                    <h5 className="card-title">{product.productName}</h5>
                                    <p className="card-text">{product.price}</p>
                                    <p className="card-text text-muted">{product.category}</p>
                                    <p className="card-text text-muted-sub">{product.subcategory}</p>
                                    <Link to={`/products/${product.id}`} className="mt-auto">
                                        <button className="btn view-details-btn w-100">
                                            <i class="fas fa-info-circle"> View Details</i>
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    ))
                )}
            </div>

            {/* Middle Banner/Slider */}
            <Carousel className="middle-carousel mb-4">
                {banners.map((banner) => (
                    <Carousel.Item key={banner.id}>
                        <img
                            className="d-block w-100 banner-img"
                            src={banner.src}
                            alt={banner.alt}
                        />
                        <Carousel.Caption>
                            <h3 className="banner-title">Special Offers Just for You!</h3>
                            <p className="banner-subtitle">Don’t miss out on our exclusive deals</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                ))}
            </Carousel>

            {/* Pagination */}
            <nav aria-label="Page navigation" className="mt-4">
                <ul className="pagination justify-content-center">
                    {Array.from({ length: totalPages }, (_, index) => (
                        <li key={index + 1} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                            <button className="page-link" onClick={() => handlePageChange(index + 1)}>
                                {index + 1}
                            </button>
                        </li>
                    ))}
                </ul>
            </nav>

            <ToastContainer />
            <Footer />
        </div>
    );
};

export default ProductList;
