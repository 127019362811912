import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Css/Footer.css';
import logo from '../assets/banners/logo/logo.jpg'; 

const Footer = () => {
    return (
        <footer className="footer bg-primary text-light py-4">
            <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        <div className="col-md-6 text-center text-md-start">
                            <img
                                src={logo}
                                alt="One Market Logo"
                                style={{ width: '120px', height: 'auto', padding: '10px' }}
                            />
                        </div>
                        <h5>Contact Us</h5>
                        <p>
                            <strong>Email:</strong> oneMarket@market.com<br />
                            <strong>Phone:</strong> +94 234 567 890<br />
                            <strong>Address:</strong> 123 Main St, Colombo, Srilanka
                        </p>
                    </div>
                    <div className="col-md-4">
                        <h5>Quick Links</h5>
                        <ul className="list-unstyled">
                            <li><a href="/oneMarket" className="text-light">Home</a></li>
                            <li><a href="/products" className="text-light">Products</a></li>
                            <li><a href="/about" className="text-light">About Us</a></li>
                            <li><a href="/faq" className="text-light">FAQ</a></li>
                            <li><a href="/contact" className="text-light">Contact</a></li>
                        </ul>
                    </div>
                    <div className="col-md-4">
                        <h5>Follow Us</h5>
                        <div className="social-media">
                            <a href="https://facebook.com" className="text-light me-3" aria-label="Facebook">
                                <i className="fab fa-facebook-f"></i>
                            </a>
                            <a href="https://twitter.com" className="text-light me-3" aria-label="Twitter">
                                <i className="fab fa-twitter"></i>
                            </a>
                            <a href="https://instagram.com" className="text-light me-3" aria-label="Instagram">
                                <i className="fab fa-instagram"></i>
                            </a>
                            <a href="https://linkedin.com" className="text-light" aria-label="LinkedIn">
                                <i className="fab fa-linkedin-in"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="row mt-4 align-items-center">
                    <div className="col-md-6 text-center text-md-end">
                        <p className="mb-0">&copy; {new Date().getFullYear()} One Market. All rights reserved.</p>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
