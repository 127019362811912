import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { db, auth, storage } from './Database/firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc, collection, addDoc, setDoc } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { FaImage, FaTag, FaMoneyBillAlt, FaAddressCard, FaPhoneAlt } from 'react-icons/fa';
import { AiOutlineFileImage } from 'react-icons/ai';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';

const ProductForm = () => {
    const [category, setCategory] = useState('');
    const [subcategory, setSubcategory] = useState('');
    const [availableSubcategories, setAvailableSubcategories] = useState([]);
    const [productName, setProductName] = useState('');
    const [description, setDescription] = useState('');
    const [price, setPrice] = useState('');
    const [images, setImages] = useState([]);
    const [address, setAddress] = useState('');
    const [sellerName, setSellerName] = useState('');
    const [contactDetails, setContactDetails] = useState('');
    const [setId] = useState(1);
    const [loading, setLoading] = useState(false);

    // Define subcategories for each category
    const subcategories = {
        Tools: ['Hand Tools', 'Power Tools', 'Garden Tools'],
        Clothing: ['Men\'s Clothing', 'Women\'s Clothing', 'Kids\' Clothing'],
        Sports: ['Fitness Equipment', 'Outdoor Gear', 'Sports Apparel'],
        Furniture: ['Living Room', 'Bedroom', 'Office Furniture'],
        Electronics: ['Phones', 'Laptops', 'Home Appliances'],
        Books: ['Fiction', 'Non-Fiction', 'Educational'],
        Toys: ['Action Figures', 'Dolls', 'Board Games'],
        Automotive: ['Car Accessories', 'Motorcycle Gear', 'Tools'],
        Home: ['DIY Tools', 'Paints', 'Lighting'],
        Kitchen: ['Cookware', 'Tableware', 'Appliances'],
        HealthBeauty: ['Skincare', 'Haircare', 'Personal Care'],
        Garden: ['Plants', 'Garden Tools', 'Outdoor Furniture'],
        OfficeSupplies: ['Stationery', 'Office Furniture', 'Technology'],
        Jewelry: ['Necklaces', 'Bracelets', 'Rings'],
        Art: ['Paintings', 'Sculptures', 'Prints'],
        Music: ['Instruments', 'Sheet Music', 'Accessories'],
        PetSupplies: ['Food', 'Toys', 'Grooming'],
        Other: ['Miscellaneous'],
    };

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
            if (currentUser) {
                // Fetch user details from Firestore
                try {
                    const userDocRef = doc(db, 'users', currentUser.uid);
                    const userDocSnap = await getDoc(userDocRef);
                    if (userDocSnap.exists()) {
                        const userData = userDocSnap.data();
                        setSellerName(userData.name || '');
                        setContactDetails(userData.phone || '');
                    } else {
                        console.log('No such document!');
                    }
                } catch (error) {
                    console.error('Error fetching user details:', error);
                    toast.error('Error fetching user details');
                }
            } else {
                // Handle unauthenticated state
                // navigate('/login'); // Uncomment if using react-router
            }
        });

        return () => unsubscribe();
    }, []);

    const handleCategoryChange = (e) => {
        const selectedCategory = e.target.value;
        setCategory(selectedCategory);
        setSubcategory('');
        setAvailableSubcategories(subcategories[selectedCategory] || []);
    };

    const handleSubcategoryChange = (e) => {
        setSubcategory(e.target.value);
    };

    const handleProductNameChange = (e) => {
        setProductName(e.target.value);
    };

    const handleDescriptionChange = (e) => {
        setDescription(e.target.value);
    };

    const handlePriceChange = (e) => {
        setPrice(e.target.value);
    };

    const handleAddressChange = (e) => {
        setAddress(e.target.value);
    };

    const handleImageChange = (e) => {
        const files = Array.from(e.target.files);
        const uploadPromises = files.map((file) => {
            return new Promise((resolve, reject) => {
                const storageRef = ref(storage, `images/${file.name}`);
                const uploadTask = uploadBytesResumable(storageRef, file);

                uploadTask.on(
                    'state_changed',
                    null,
                    (error) => {
                        reject(error);
                    },
                    () => {
                        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                            resolve(downloadURL);
                        });
                    }
                );
            });
        });

        Promise.all(uploadPromises).then((urls) => {
            setImages(urls);
        }).catch((error) => {
            console.error('Error uploading images:', error);
        });
    };

    const navigate = useNavigate();

    const handleSubmit = async (e) => {

        e.preventDefault();

        const randomId = uuidv4();

        const errors = {};
        if (!productName.trim()) {
            errors.productName = 'Product Name is required';
        }
        if (!description.trim()) {
            errors.description = 'Description is required';
        }
        if (!price.trim()) {
            errors.price = 'Price is required';
        }
        if (!category.trim()) {
            errors.category = 'Category is required';
        }
        if (!subcategory.trim()) {
            errors.subcategory = 'Subcategory is required';
        }
        if (images.length === 0) {
            errors.images = 'At least one image is required';
        }
        if (!address.trim()) {
            errors.address = 'Address is required';
        }
        if (!contactDetails.trim()) {
            errors.contactDetails = 'Contact Details are required';
        }

        if (Object.keys(errors).length === 0) {
            const product = {
                id: randomId,
                productName,
                description,
                price,
                images,
                category,
                subcategory,
                address,
                sellerName,
                contactDetails,
            };

            setLoading(true);  // Start loading
            const loadingToastId = toast.loading("Adding product, please wait...");

            try {

                await setDoc(doc(db, 'products', randomId), product);

                toast.update(loadingToastId, {
                    render: "Product added successfully!",
                    type: "success",
                    isLoading: false,
                    autoClose: 3000,
                });

                setProductName('');
                setDescription('');
                setPrice('');
                setImages([]);
                setCategory('');
                setSubcategory('');
                setAddress('');
                setContactDetails('');

                toast.success('Product added successfully');
                navigate('/manageProducts');
            } catch (error) {
                toast.update(loadingToastId, {
                    render: "Error adding product: " + error.message,
                    type: "error",
                    isLoading: false,
                    autoClose: 5000,
                });
            } finally {
                setLoading(false);
            }
        } else {
            Object.values(errors).forEach((error) => {
                toast.error(error);
            });
        }
    };

    return (
        <div className="container" style={{ width: '60%', maxWidth: '800px', margin: '0 auto' }}>
            <h2 className="text-center mb-4">Add Product</h2>
            <form onSubmit={handleSubmit} className="p-4 border rounded shadow-sm bg-light">
                <div className="form-group mb-3">
                    <label htmlFor="category" className="d-flex align-items-center">
                        <FaTag className="me-2" /> Category:
                    </label>
                    <select
                        className="form-control"
                        id="category"
                        name="category"
                        value={category}
                        onChange={handleCategoryChange}
                    >
                        <option value="">Select a category</option>
                        {Object.keys(subcategories).map((key) => (
                            <option key={key} value={key}>{key}</option>
                        ))}
                    </select>
                </div>
                <div className="form-group mb-3">
                    <label htmlFor="subcategory" className="d-flex align-items-center">
                        <FaTag className="me-2" /> Subcategory:
                    </label>
                    <select
                        className="form-control"
                        id="subcategory"
                        name="subcategory"
                        value={subcategory}
                        onChange={handleSubcategoryChange}
                        disabled={availableSubcategories.length === 0}
                    >
                        <option value="">Select a subcategory</option>
                        {availableSubcategories.map((sub, index) => (
                            <option key={index} value={sub}>{sub}</option>
                        ))}
                    </select>
                </div>
                <div className="form-group mb-3">
                    <label htmlFor="productName" className="d-flex align-items-center">
                        <AiOutlineFileImage className="me-2" /> Product Name:
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        id="productName"
                        name="productName"
                        value={productName}
                        onChange={handleProductNameChange}
                        placeholder="Enter product name"
                    />
                </div>
                <div className="form-group mb-3">
                    <label htmlFor="description" className="d-flex align-items-center">
                        <FaImage className="me-2" /> Description:
                    </label>
                    <textarea
                        className="form-control"
                        id="description"
                        name="description"
                        value={description}
                        onChange={handleDescriptionChange}
                        placeholder="Enter product description"
                    ></textarea>
                </div>
                <div className="form-group mb-3">
                    <label htmlFor="price" className="d-flex align-items-center">
                        <FaMoneyBillAlt className="me-2" /> Price:
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        id="price"
                        name="price"
                        value={price}
                        onChange={handlePriceChange}
                        placeholder="Enter product price"
                    />
                </div>
                <div className="form-group mb-3">
                    <label htmlFor="address" className="d-flex align-items-center">
                        <FaAddressCard className="me-2" /> Address:
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        id="address"
                        name="address"
                        value={address}
                        onChange={handleAddressChange}
                        placeholder="Enter address"
                    />
                </div>
                <div className="form-group mb-3">
                    <label htmlFor="contactDetails" className="d-flex align-items-center">
                        <FaPhoneAlt className="me-2" /> Contact Details:
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        id="contactDetails"
                        name="contactDetails"
                        value={contactDetails}
                        onChange={(e) => setContactDetails(e.target.value)}
                        readOnly
                    />
                </div>
                <div className="form-group mb-3">
                    <label htmlFor="image" className="d-flex align-items-center">
                        <FaImage className="me-2" /> Images:
                    </label>
                    <input
                        type="file"
                        className="form-control-file"
                        id="image"
                        name="images"
                        accept="image/*"
                        multiple
                        onChange={handleImageChange}
                    />
                </div>
                {images.length > 0 && (
                    <div className="image-preview mt-3 d-flex flex-wrap">
                        {images.map((image, index) => (
                            <img
                                key={index}
                                src={image}
                                alt={`Preview ${index + 1}`}
                                style={{ width: '100px', height: '100px', margin: '10px', borderRadius: '8px' }}
                            />
                        ))}
                    </div>
                )}
                <button type="submit" className="btn btn-primary mt-3 w-100">
                    Submit
                </button>
            </form>
            <ToastContainer />
        </div>

    );
};

export default ProductForm;
