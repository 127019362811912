import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { db, auth } from './Database/firebase';  // Import Firestore database and auth
import { collection, getDocs, deleteDoc, doc, query, where, getDoc } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import '../Css/ProductList.css';

const ManageProducts = () => {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [userName, setUserName] = useState(null);
    const navigate = useNavigate();  // Initialize useNavigate

    useEffect(() => {
        const fetchUserName = async (userId) => {
            try {
                const userDoc = doc(db, 'users', userId);
                const userSnapshot = await getDoc(userDoc);
                if (userSnapshot.exists()) {
                    setUserName(userSnapshot.data().name);
                } else {
                    setError('User not found');
                }
            } catch (error) {
                setError('Error fetching user data');
            }
        };

        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            if (currentUser) {
                fetchUserName(currentUser.uid);
            } else {
                setUserName(null);
            }
        });

        return () => unsubscribe();
    }, []);

    useEffect(() => {
        if (userName) {
            const fetchProducts = async () => {
                try {
                    const q = query(collection(db, 'products'), where('sellerName', '==', userName));
                    const querySnapshot = await getDocs(q);
                    const productsData = querySnapshot.docs.map(doc => ({
                        id: doc.id,
                        ...doc.data()
                    }));
                    setProducts(productsData);
                } catch (error) {
                    setError('Error fetching products');
                } finally {
                    setLoading(false);
                }
            };

            fetchProducts();
        }
    }, [userName]);

    const handleDelete = async (id) => {
        try {
            const docId = String(id);
            console.log('Attempting to delete document with ID:', docId);
            await deleteDoc(doc(db, 'products', docId));
            setProducts(products.filter(product => product.id !== docId));
            navigate('/manageProducts');
        } catch (error) {
            setError('Error deleting product');
            console.error('Error deleting product:', error);
        }
    };

    if (loading) return <p className="text-center">Loading products...</p>;
    if (error) return <p className="text-center text-danger">{error}</p>;

    return (
        <div className="container mt-5">
            <h2 className="text-center mb-4">Manage Products</h2>
            <div className="row">
                {products.length === 0 ? (
                    <p className="text-center">No products available.</p>
                ) : (
                    products.map((product) => (
                        <div key={product.id} className="col-md-4 col-lg-3 mb-4">
                            <div className="card h-100 shadow-sm">
                                <div className="card-img-top">
                                    <img
                                        src={product.images[0]}
                                        alt={product.productName}
                                        className="card-img-top"
                                    />
                                </div>
                                <div className="card-body d-flex flex-column">
                                    <h5 className="card-title" style={{ fontSize: '1.25rem', fontWeight: 'bold' }}>
                                        {product.productName}
                                    </h5>
                                    <p className="card-text text-muted" style={{ fontSize: '1rem' }}>
                                        LKR {product.price}
                                    </p>
                                    <div className="mt-auto">
                                        <Link to={`/products/${product.id}`}>
                                            <button className="btn btn-primary w-100 mb-2">
                                                View Details
                                            </button>
                                        </Link>
                                        <button 
                                            className="btn btn-danger w-100" 
                                            onClick={() => handleDelete(product.id)}
                                        >
                                            Delete
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
};

export default ManageProducts;
