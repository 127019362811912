import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom'; // Import useNavigate
import { createUserWithEmailAndPassword } from "firebase/auth"; 
import { auth, db, realTimeDb } from './Database/firebase'; // Import Realtime Database
import { doc, setDoc } from "firebase/firestore";
import { ref, set } from "firebase/database"; // Import Realtime Database methods
import '../Css/Login.css';

const Signup = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        password: '',
        retypePassword: '',
        phone: '',
        address: '',
        role: 'Customer' 
    });
    const [error, setError] = useState(null); 
    const [success, setSuccess] = useState(false); 
    const [showDialog, setShowDialog] = useState(false); 
    const navigate = useNavigate(); // Initialize navigate

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { name, email, password, retypePassword, phone, address, role } = formData;

        // Simple validation to ensure passwords match
        if (password !== retypePassword) {
            setError("Passwords do not match");
            return;
        }

        try {
            // Firebase method to create a user
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            // Storing user data (including role) in Firestore
            await setDoc(doc(db, "users", user.uid), {
                name: name,
                email: email,
                phone: phone,
                address: address,
                role: role,
            });

            // Storing user data in Firebase Realtime Database
            await set(ref(realTimeDb, 'users/' + user.uid), {
                name: name,
                email: email,
                phone: phone,
                address: address,
                role: role,
            });

            console.log("User created and data saved:", user);
            setSuccess(true); // Set success state
            setShowDialog(true); // Show the dialog box
            setFormData({
                name: '',
                email: '',
                password: '',
                retypePassword: '',
                phone: '',
                address: '',
                role: 'Customer'
            });
            setError(null); // Clear any previous errors

            // Redirect to home page after a short delay to allow dialog box to be seen
            setTimeout(() => {
                navigate('/'); // Redirect to home page
            }, 2000); // Adjust delay as needed
        } catch (error) {
            console.error("Error creating user:", error);
            setError(error.message); // Display error message
        }
    };

    const closeDialog = () => {
        setShowDialog(false);
    };

    return (
        <div className="container mt-5">
            <h2 className="text-center mb-4">Sign Up</h2>
            <div className="login-container row justify-content-center">
                <div className="login-form-wrapper">
                    {error && <div className="alert alert-danger">{error}</div>}
                    {success && <div className="alert alert-success">Account created successfully!</div>}
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label htmlFor="name">
                                <i className="fas fa-user"></i> Name:
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                placeholder="Enter your name"
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">
                                <i className="fas fa-envelope"></i> Email:
                            </label>
                            <input
                                type="email"
                                className="form-control"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                placeholder="Enter your email"
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="password">
                                <i className="fas fa-lock"></i> Password:
                            </label>
                            <input
                                type="password"
                                className="form-control"
                                id="password"
                                name="password"
                                value={formData.password}
                                onChange={handleChange}
                                placeholder="Enter your password"
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="retypePassword">
                                <i className="fas fa-lock"></i> Retype Password:
                            </label>
                            <input
                                type="password"
                                className="form-control"
                                id="retypePassword"
                                name="retypePassword"
                                value={formData.retypePassword}
                                onChange={handleChange}
                                placeholder="Retype your password"
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="phone">
                                <i className="fas fa-phone"></i> Phone Number:
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="phone"
                                name="phone"
                                value={formData.phone}
                                onChange={handleChange}
                                placeholder="Enter your phone number"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="address">
                                <i className="fas fa-address-card"></i> Address:
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="address"
                                name="address"
                                value={formData.address}
                                onChange={handleChange}
                                placeholder="Enter your address"
                            />
                        </div>
                        <button type="submit" className="btn btn-primary btn-block">
                            Sign Up
                        </button>
                    </form>
                    <div className="text-center mt-4">
                        <p>Already have an account?</p>
                        <Link to="/login" className="btn btn-secondary btn-block">
                            Login
                        </Link>
                    </div>
                </div>
            </div>

            {/* Dialog Box */}
            {showDialog && (
                <div className="dialog-box">
                    <div className="dialog-content">
                        <h4>Signup Successful!</h4>
                        <p>Your account has been created successfully.</p>
                        <button onClick={closeDialog} className="btn btn-primary">Close</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Signup;
