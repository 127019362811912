import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaGoogle } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { auth, db } from './Database/firebase';
import { doc, getDoc } from 'firebase/firestore';
import '../Css/Login.css';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        setError(null);
        try {
            // Firebase email/password login
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            // Get user data from Firestore to check role
            const userDoc = await getDoc(doc(db, "users", user.uid));
            if (userDoc.exists()) {
                const userData = userDoc.data();
                if (userData.role === 'Admin') {
                    navigate('/admin-dashboard');
                } else {
                    navigate('/oneMarket');
                }
            }
        } catch (error) {
            setError("Login failed. Please check your credentials.");
            console.error("Error logging in:", error);
        }
    };

    const handleGoogleLogin = async () => {
        try {
            const provider = new GoogleAuthProvider();
            const result = await signInWithPopup(auth, provider);
            const user = result.user;

            // Check Firestore for user role
            const userDoc = await getDoc(doc(db, "users", user.uid));
            if (userDoc.exists()) {
                const userData = userDoc.data();
                if (userData.role === 'Admin') {
                    navigate('/admin-dashboard');
                } else {
                    navigate('/oneMarket');
                }
            }
        } catch (error) {
            setError("Google login failed.");
            console.error("Error logging in with Google:", error);
        }
    };

    return (
        <div className="container mt-5">
            <h2 className="text-center mb-4">Login</h2>
            <div className="login-container">
                <div className="login-form-wrapper">
                    {error && <div className="alert alert-danger">{error}</div>}
                    <form onSubmit={handleLogin}>
                        <div className="form-group">
                            <label htmlFor="email">
                                <i className="fas fa-envelope"></i> Email:
                            </label>
                            <input
                                type="email"
                                className="form-control"
                                id="email"
                                placeholder="Enter your email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="password">
                                <i className="fas fa-lock"></i> Password:
                            </label>
                            <input
                                type="password"
                                className="form-control"
                                id="password"
                                placeholder="Enter your password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                        </div>
                        <button type="submit" className="btn btn-primary">
                            Login
                        </button>
                        <hr className="my-4" />
                        <div className="text-center">
                            <p>Or login with:</p>
                            <div className="social-buttons">
                                <button type="button" className="social-btn btn btn-outline-dark mx-1" onClick={handleGoogleLogin}>
                                    <FaGoogle /> Google
                                </button>
                            </div>
                            <div className="mt-3">
                                <p>Don't have an account?</p>
                                <Link to="/signup" className="btn btn-secondary btn-block">
                                    Sign Up
                                </Link>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Login;
