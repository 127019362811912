import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth, db } from './Database/firebase';
import { doc, getDoc } from 'firebase/firestore';

const ProtectedRoute = ({ element: Component, roleRequired, ...rest }) => {
  const [user, setUser] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        // Fetch user role from Firestore
        const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setUserRole(userData.role);
        }
      } else {
        setUser(null);
        setUserRole(null);
      }
      setLoading(false);
    });

    // Cleanup the subscription on unmount
    return () => unsubscribe();
  }, []);

  if (loading) {
    return <div>Loading...</div>; // Show a loading indicator while checking auth
  }

  // If user is authenticated and has the required role, render the component
  if (user && (!roleRequired || userRole === roleRequired)) {
    return <Component {...rest} />;
  } else {
    // Redirect to home page if not authenticated or not authorized
    return <Navigate to="/login" replace />;
  }
};

export default ProtectedRoute;
